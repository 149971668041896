






























import { Component, Prop, Mixins } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import MasterDepositoryModule from '@/store/modules/master/depository'

@Component({
  components: {
    Confirmation,
  },
})
export default class MasterMobileFoldersModal extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private visible!: boolean

  private get monthID () {
    return +this.$route.params.monthID
  }

  private get courseID() {
    return +this.$route.params.courseID
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('close', false)
  }

  private get folders() {
    return MasterDepositoryModule.trainingFilesTree
  }

  private cleanup() {
    this.handleClose()
  }

  private handleDownload(id: number) {
    this.$emit('download', id)
  }
}
